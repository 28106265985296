body{
    font-family: 'Open Sans', sans-serif;
      margin: 0;
      color: #102434;

  }

  html {
    height: 100%;
    box-sizing: border-box;
  }
  
  a{
    text-decoration: none;
    border: none;
  }
  
  a:hover, a:active, a:visited {
    text-decoration: none;
  }
  
  h4, h3, h2{
    font-weight: 700;
  }
  
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  
  .page-container{
    position: relative;
    background-position: top;
    min-height: 100vh;
  
  }
  
  .upper-container{
    width: 100%;
    background-color: #fff;
    /* padding: 0 25px; */

  }
  
  .nav-header{
    padding-top: 25px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 22px;
    max-width: 1800px;
    margin: 0 auto;
    min-height: 120px;
    line-height:  80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    position: relative;
  }
  
  .nav-btn{
    display: flex;
    flex: 1;
      vertical-align: middle;
    max-width: 70%;
        align-items: center;
  }
  
  .nav-btn:hover, .nav-btn:active, .nav-btn:visited, .nav-btn:focus{
    text-decoration: none;
  
  }
  
  .logo-special{
      background-image: url('https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2FSteris%2Flogos600.png?alt=media&token=5a31077c-6e4e-4c44-a3ef-bad28f1cad73');
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      max-width: 100%;
      height: 60px;
      vertical-align: middle;
  }

.logo-form img {
    max-width: 100%;
    height: auto !important;
}

.logo-form {
  margin: 0 -17px;
}

@media (max-width: 600px){
  .logo-form {
    margin: 0 -8px;
  }
}
  
  /* versão antiga
  .logo-special{
    background-image: url('../assets/images/specialProgramLogo.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 200px;
    max-width: 40%;
    height: 75px;
    vertical-align: middle;
  }
  */
  
  
  .logo-steris{
    background-image: url('../assets/images/sterislogo.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 150px;
    height: 34px;
    vertical-align: middle;
  }
  
  
  /*
  .logo-steris-fps{
    background-image: url('../assets/images/sterisfpslogo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 350px;
    max-width: 40%;
    height: 75px;
    vertical-align: middle;
  }
  */
  
  .profile-btn{
    align-self: flex-end; justify-content: flex-end;
  }
  
  @media(max-width: 720px){
    .nav-header{
        padding-left: 10px;
        padding-right: 10px;
    }
  
    /* versão antiga
     .logo-special{
      background-image: url('../assets/images/specialProgramLogo.png');
      background-size: contain;
      background-repeat: no-repeat;
      width: 200px;
      max-width: 50%;
      height: 75px;
      vertical-align: middle;
    }
    .logo-steris-fps{
      max-width: 50%;
      height: 35px;
    }
  */
  
    .logo-steris-fps{
      max-width: 50%;
      height: 0;
    }
  
    .profile-btn{
      align-self: flex-start;
      position: absolute;
      top: 10px;
      right: 0;
    }
  
  
  }
  
  .get-certificado h4,
  .videos-get-certificate h4 {
      font-size: 22px;
  }
  .get-certificado p,
  .videos-get-certificate p {
      font-size: 18px;
  }
  
  .estrelas {
      display: flex;
      justify-content: center;
  }  
  @media(max-width: 480px){
    .logo-special{
      height: 60px;
      background-position: center;
    }
    .logo-steris-fps{
      max-width: 50%;
      height: 0;
      background-position: center;
    }
    
    /* versão antiga
     .logo-steris-fps{
      max-width: 50%;
      height: 25px;
      background-position: center;
    }
    */
  }
  
  
  .profile-container{
    height: 60px;
    width: 300px;
    line-height: normal;
  }
  
  .profile-thumbnail{
    float: left;
    display: flex;
    width: 60px;
    height: 60px;
    /* background-image: url('../assets/images/profilePic.png');
  
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
    border-radius: 30px;
  }
  
  .profile-text{
    padding-left: 70px;
  }
  
  .profile-text h4, .profile-text p{
    margin: 5px;
  }
  
  @media (max-width: 600px){
    .profile-text{
      display: none;
      visibility: hidden;
    }
    .top_navbar .menu {
      width: 120px;
  }
  
    .profile-container{
      width: 70px;
    }
  }
  
  .title-container{
    background-color: #ED1C24;
    height: 61px;
    width: 100%;
  }
  
  .title-container-adm{
    background-color: #4F6C87;
    height: 61px;
    width: 100%;
    margin-left: 50px;
  }
  
  .title-container p {
    display: block;
    max-width: 1800px;
    margin: 0 auto;
    color: #fff;
    font-size: 20px;
    line-height: 61px;
    padding: 0 50px;
  }
  
  .title-container-adm p{
    display: block;
    max-width: 1800px;
    margin: 0 auto;
    color: #fff;
    font-size: 24px;
    line-height: 61px;
    padding: 0 50px;
  
  }
  
  .main-container{
    display: block;
    margin: 0 auto 0px auto;
    padding-top: 30px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 170px;
  
  }
  
  @media(max-width: 600px){
    .main-container{
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 20px;
    }
  
  }
  
  .main-container-adm{
    display: block;
    margin: 0 auto 0px auto;
    padding-top: 30px;
    padding-left: 150px;
    padding-right: 50px;
    padding-bottom: 140px;
  
  }



  
  
  
  

 
  
  
  
  
  
  
  
  
  
 
  
  .video-button0{
    color: #102434;
  }
  
  .videos-panel0{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 1700px;
    margin: 0 auto;
  
  }
  

  .viewStatpacado{
    display: flex;
    padding: 10px;
  }
  
  .video-panel0::after{
    content: "";
    flex: auto;
  }
  
  
  
  .get-certificate-button{
    margin: 0;
    background-color: #002D6E;
    color: #fff;
    padding: 7px 17px;
    margin-top: 10px;
    border-radius: 7px;
    font-weight: 500;
  }
  
  .get-certificate-button:hover{
    color: #fff;
  }
  
  .video-button0{
    display: inline-block;
    position: relative;
    max-height: 300px;
    min-width: 300px;
    max-height: auto;
    margin: 30px 0;
    background-color: #fff;
    border-radius: 7px 7px 7px 7px;
    box-shadow: #ddd 0 0 20px;
    order: 2;
  }
  .video-playbutton0{
    position: absolute;
    top: 65px;
    right: 50%;
    margin-right: -32px;
    display: block;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2FSteris%2Fplay-button.png?alt=media&token=3022793d-71fa-4cad-826e-4d973d605f47');
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    transition: All 0.1s;
  }
  

  @media(max-width: 800px){
    .video-button0{
      min-width: calc(100vw *0.8);
      max-height: 550px;
    }
    .videos-panel0{
      flex-direction: column;
      align-items: center ;
    }
    .video-item-background0{
      min-height: 300px;
    }
    .video-playbutton0{
      top: 120px;
    }
  }
  
  .videos-get-certificate{
    order: 1;
    width: 1800px;
  }
  
  .grid-fix{
    max-width: calc(100% * (1/4) - 30px - 1px);
    width: 100%;
  }
  
  
  /*
  .video-button:hover .video-playbutton{
    width: 50px;
    margin-right: -25px;
    transition-timing-function: ease-in-out;
  }
  */
  
  
  
  /* .video-check-active{
    position: absolute;
    top: 10px;
    right: 10px;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #6d6;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2FSteris%2Fcheck-active.png?alt=media&token=292c5b1a-3877-478d-b0c4-63bce25a2ad6');
    background-size: contain;
    background-position: center center;
  }
  .video-check{
    position: absolute;
    top: 10px;
    right: 10px;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2FSteris%2Fcheck-off.png?alt=media&token=9b4fe620-f58a-46ab-acf4-ff7a69b69974');
    background-size: contain;
    background-position: center center;
  } */
  
  

  
  .video-item-background0{
    display: block;
    width: 100%;
    height: 200px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    /* background-image: url('../assets/images/videothumbs/01.png'); */
    background-size: cover;
  }
  
  .video-text0{
    margin: 30px 20px;
  }
  
  .video-text0 h3{
    font-weight: 700;
  }
  
  @media (max-width: 1680px) {
    .video-button0{
      max-width: calc(100% * (1/3) - 24px - 1px);
    }
  
    .grid-fix{
      max-width: calc(100% * (1/3) - 24px - 1px);
    }
  
  }
  
  @media (max-width: 1270px) {
    .video-button0{
      max-width: calc(100% * (1/2) - 20px - 1px);
    }
  
    .grid-fix{
      max-width: calc(100% * (1/2) - 20px - 1px);
    }
  }
  
  @media (max-width: 1000px) {
    .title-container p{
      font-size: 15px;
    }
  }
  
  @media (max-width: 760px) {
    .videos-panel0 a{
      max-width: 100%;
    }
  
    .grid-fix{
      max-width: 100%;
    }
  }
  
  @media(max-width: 640px){
    .title-container p{
      line-height: 30px;
    }
    .title-container{
      height: auto;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  
  
  .footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    /* min-height: 129px; */
    padding: 30px;
    background-color: #E6E6E6;
    color: #000;
    font-family: 'Segoe UI', sans-serif;
    font-size: 16px;
  }
  .footer-copyright p {
    margin: 0;
}
  
  .footer-center{
    display: flex;
    width: 1700px;
    margin: 0 auto;
    max-width: 90%;
    font-size: 12px;
  }
  
  
  .footer-center div{
    display:  flex;
    flex: 1;
  }
  
  .footer-right-menu{
    text-align: right;
    align-self: flex-end;
    justify-content: flex-end;
  }
  
  .footer-right-menu a {
    color: #000;
    padding: 0 10px;
  }
  
  @media(max-width: 1000px){
    .footer{
      padding-top: 20px;
      padding-bottom: 20px;
      min-height: 110px;
    }
  
    .footer-center{
      flex-direction: column;
    }
  
    .footer-right-menu{
      text-align: center ;
      align-self: center;
      justify-content: center;
    }
  
    .footer-copyright{
      padding-top: 20px;
      text-align: center ;
      align-self: center;
      justify-content: center;
      order: 2;
    }
  }
  
  
  /* -------- Video Page --------- */
  .video-flex{
    display: flex;
    width: 1600px;
    max-width: 100%;
    margin: 0 auto;
  }
  
  
  .video-container{
    display: inline-block;
    width: 100%;
    /* height: 700px; */
    vertical-align: top;
    margin-top: 20px;
  }
  
  .video-container iframe{
    width: 100%;
    max-height: 100%;
    resize: both;
    overflow: auto;
    background-color: #000;
    border-radius: 7px;
  }
  
  .video-side-options{
    display: inline-block;
    width: 300px;
    /*height: 300px;*/
    vertical-align: top;
    padding-bottom: 20px;
  
  }
  
  .side-block{
    display: block;
    width: 100%;
    background-color: #fff;
    border-radius: 7px 7px 7px 7px;
    box-shadow: #d9d9d9 0 0 25px;
    margin: 30px;
    padding: 6px 25px; 
    /* padding: 8px 25px; */
  }
  
  @media(max-width: 1000px){
    .video-flex{
      flex-direction: column;
    }
  
    .video-container iframe{
      max-height: 300px;
    }

    .video-container{
      height: 300px;
    }
  
    .video-side-options{
      display: inline-block;
      width: 100%;
    }
  
    .side-block{
      margin: 10px 0px;
    }
  }
  
  .button-all-videos{
    display: block;
    width: 100%;
    min-height: 28px;
    line-height: 28px;
    padding-left: 40px;
    color: #000;
    font-weight: 700;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2FSteris%2Fdownload.png?alt=media&token=0022de42-084b-4ede-9bb9-220591cd214a');
    background-repeat: no-repeat;
    background-position: left;
    margin: 5px 0px;
    font-size: 18px;
  }
  
  .video-header-comment{
    display: block;
    width: 100%;
    min-height: 28px;
    line-height: 28px;
    padding-left: 50px;
    color: #000;
    font-weight: 700;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2FSteris%2Fcomments.png?alt=media&token=19cc5da1-d7a6-4ed3-923d-9a61ef89006d');
    background-repeat: no-repeat;
    background-position: left;
    margin: 5px 0px;
    font-size: 18px;
  }
  
  .button-post-comment{
    background-color: #ed1c24;
    color: #fff;
    border-radius: 7px;
    padding: 11px 40px;
  }
  
  .button-post-comment:hover, .button-post-comment:active, .button-post-comment:visited {
    color: #eee;
    text-decoration: none;
  }
  
  .side-block h3{
    font-size: 20px;
  }
  
  .file-block{
    display: block;
    margin: 10px 0;
    padding: 10px 0px 5px 50px;
    min-height: 50px;
    background-image: url('../assets/images/gIconPDF.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: left;
    transition: 0.2s All;
  }
  
  .file-block:hover{
    background-color: #f9f9f9;
  }
  
  .sidebar-menu{
    position: fixed;
    top: 0;
    left: -210px;
    width: 270px;
    height: 100%;
    background-color: #4D90CD;
    color: #fff;
    padding: 10px;
  }
  
  .sidebar-menu a{
    color: #fff;
  }
  
  .sidebar-itens a{
    display: block;
    background-image: url('../assets/images/sidebar-icons/adms.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: 215px;
    padding: 3px 5px;
    margin: 15px 0;
  }
  
  .sidebar-top{
    flex: auto;
    display: flex;
  }
  
  .sidebar-top div{
    flex: 1;
  
  }
  
  #sidebar-open-button{
    display: block;
    font-size: 18px;
    width: 35px;
    height: 35px;
    float: right;
  }
  
  .blue-background{
    /* background-image: url(); */
    min-height: 150px;
    margin-bottom: 0px;
  }
  
  .login-container{
    display: flex;
    flex-direction: row-reverse;
    /* align-items: flex-end; */
    max-width: 1000px;
    margin: 0 auto;
  }

  .logoCathim{
    display: none !important;
  }

  @media(max-width: 900px){
    .login-container{
      flex-direction: column;
    }
    .footerviewlogincontainer{
      width: 90vw !important;
    }
    .metadeView{
      width: 100%;
    }

    .logoCathim{
      margin-top: -150px;
      display: block !important;
    }
    
  }
  
  @media(min-height: 800px){
    .login-container{
      display: flex;
      /* flex-direction: column; */
      max-width: 1000px;
      margin: 100px auto;
    }

   
  }
  

  
  .login-page-container-adm{
    background-image: url('../assets/images/bg.jpg');
    min-height:100vh;
    background-size: cover;
    background-repeat: no-repeat;
  
  }
  
  .blue-background h2{
    color: #000;
    font-weight: 400;
    font-size: 26px;
  }
  
  /* .login-form{
  
  } */
  /*Formik*/
  input.form-control, select.form-control {
    padding: 15px 15px;
    border-radius: 5px;
    border: 1px solid #C7C7C7;
    font-size: 14px;
    max-width: 730px;
    width: 100%;
    height: auto;
    margin: 3px 0;
}
  .login-button{
    padding: 17px 15px;
    border-radius: 5px;
    border: 1px solid #C7C7C7;
    font-size: 14px;
    max-width: 260px;
    width: 90%;
    margin: 3px 0;
    background-color: #FFAA00;
    color: #002D6E;
    font-weight: 600;
    font-size: 16px;
    text-align:center;
  }

  .is-invalid~.invalid-feedback, .is-invalid~.invalid-tooltip, .was-validated :invalid~.invalid-feedback, .was-validated :invalid~.invalid-tooltip {
    display: block;
}
  button.btn.btn-line {
    padding: 17px 15px;
    border-radius: 5px;
    border: none;
    font-size: 14px;
    max-width: 260px;
    width: 90%;
    margin: 3px 0;
    background-color: #FFAA00;
    color: #002D6E;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
}
a.inscrito {
  cursor: pointer;
  color: #fff;
  padding: 17px 0px;
  margin: 0 15px;
}
.is-invalid~.invalid-feedback {
  display: block;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: .1rem;
  font-size: 15px;
  color: #dc3545;
}
  /* Fim Formik*/


  .login-form input[type=text], .login-form input[type=password]{
    padding: 15px 15px;
    border-radius: 5px;
    border: none;
    font-size: 14px;
    max-width: 500px;
    width: 100%;
    margin: 3px 0;
    border: 1px solid #C7C7C7;
  }
  
  .login-form select{
    padding: 15px 15px;
    border-radius: 5px;
    border: none;
    font-size: 14px;
    max-width: 730px;
    width: 100%;
    margin: 3px 0;
  }
 
 
  
  .login-button{
    padding: 17px 15px;
    border-radius: 5px;
    border: none;
    font-size: 14px;
    max-width: 260px;
    width: 90%;
    margin: 3px 0;   
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    text-align:center;
    background-color: #AE011A;
  }
.button-login{
  background-color: #ED1C24;
}

.button-register:hover{
  background-color: #AE011A;
  color: #fff;;
}

  
  .login-button-adm {
    padding: 17px 15px;
    border-radius: 5px;
    border: none;
    font-size: 14px;
    max-width: 260px;
    width: 90%;
    margin: 3px 0;
    background-color: #175E9E;
    color: #FFF;
    font-weight: 600;
    font-size: 16px;
  }
  
  .language-btn{
    width: 40px;
    margin: 0 5px;
    background-image: url('../assets/images/language_flags/BRAZIL.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    border-radius: 7px;
  }
  
  .flag-pt-br{
    background-image: url('../assets/images/language_flags/BRAZIL.png');
  }
  
  .flag-en{
    background-image: url('../assets/images/language_flags/UK.png');
  }
  
  .flag-es{
    background-image: url('../assets/images/language_flags/SP.png');
  }
  
  @media(max-width: 950px){
      .lang-area span{
        visibility: hidden;
        display: none;
      }
  }
  
  @media(max-width: 700px){
      
    .lang-area{
      position: relative;
      top: 4px;
      right: 35px;
      line-height: 11;
    }
    
    /* versão antiga
    .lang-area{
      position: absolute;
      top: 0px;
      right: 0px;
      line-height: 30px;
    }*/
  
  
    .language-btn{
        width: 30px;
      margin: 0 2px;
    }
  
    .login-button{
      margin: 5px 0;
    }
  }
  
  
  
  .form-final-line{
    display: block;
    /* padding-bottom: 20px; */
    max-width: 500px;
  }
  
  .form-final-line div{
    display: flex;
    width: 100%;
  }
  
  @media(max-width: 700px){
    .form-final-line{
      flex-direction: column;
      display: flex;
    }
  
    .login-button{
      max-width: 100%;
      width: 100%;
    }
  }
  
  .form-password-reset {
    color: #23527c;
    justify-content: flex-end;
  }
  
  .form-password-reset:hover{
    color: #000;
  
  }
  
  .button-login-active:hover{
    background-color:  #23527c;
    color: #fff;
  }
  
  .resend-email-button{
    background-color:  #FFAA00;
    color: #002D6E;
    padding: 3px 15px;
    border-radius: 7px;
    font-weight: 600;
  }
  
  .profile-menu-relative-container{
    display:none;
    background-color: #cfc;
    max-width: 1800px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  }
  
  @media(max-width: 720px){
    .profile-menu-relative-container{
      max-width: 1px;
      position: relative;
    }
  
  }
  
  
  .profile-menu-shadow {
      width: 300px;
      z-index: 1;
      mix-blend-mode: multiply;
      transition: all 250ms;
      box-shadow: 0 0 5px rgba(100, 106, 132, 0.5), 0 5px 12px rgba(100,106,136,.8);
    border-radius: 5px;
    padding: 10px 10px;
  }
  
  .profile-menu-shadow a{
    margin: 0;
    display: block;
    padding: 5px 0px 5px 30px;
  }
  
  .edit-main-container{
    display: block;
    max-width: 850px;
    width: 90%;
    margin: 0 auto;
    padding: 0px;
  
  }
  
  .edit-main-container h3{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center left;
    padding: 3px 0 3px 35px;
    margin: 25px 0;
    font-size: 18px;
  }
  
  .edit-profile-title{
    background-image: url('../assets/images/formicons/user.png');
  }
  
  .edit-main-container p{
    margin: 15px 0;
  }
  
  .edit-main-container input[type=submit]{
    background-color: #4D90CD;
    color: #fff;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    padding: 10px 20px;
    transition: 0.2s all;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
    -ie-transition: 0.2s all;
    -o-transition: 0.2s all;
  }
  
  .edit-main-container input[type=submit]:hover{
    background-color: #4F6C87;
  }
  
  .edit-main-container input[type=text], .edit-main-container input[type=password], .edit-main-container input[type=number]{
    padding: 10px 40px 10px 20px;
    border: solid 1px #DDD;
    width: 360px;
    max-width: 100%;
    border-radius: 5px;
    font-size: 16px;
    /* background-image: url('../assets/images/formicons/Pen.png'); */
    background-repeat: no-repeat;
    background-position: center right 10px;
    background-size: 24px 24px;
  }
  
  .edit-general-info label{
    min-width: 75px;
    font-size: 16px;
    font-weight: 700;
  }
  
  .edit-new-password input[type=text], .edit-new-password input[type=password]{
    padding: 10px 40px 10px 20px;
    border: solid 1px #DDD;
    width: 360px;
    max-width: 100%;
    border-radius: 5px;
    font-size: 16px;
  }
  
  
  .edit-general-info textarea{
    resize: none;
    height: 120px;
    padding: 10px 40px 10px 20px;
    border: solid 1px #DDD;
    width: 440px;
    max-width: 100%;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .edit-new-password label{
    min-width: 125px;
    font-size: 16px;
    font-weight: 700;
  }
  
  .big-profile-picture{
    display: block;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-size: contain;
    background-position: center;
  }
  
  .star-container{
    font-size: 35px;
    transition: 0.3s All;
  }
  
  .fa{
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 30px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: 0.3s All;
  
  }
  
  
  .star-rating .fa-star{color:#4B90CD;transition: 0.3s All;}
  
  /* Customize the label (the container) */
  .container {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #4B90CD;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: inline-block;
  }
  
  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
  
  .container{
    min-width: 75px;
    font-size: 14px;
    font-weight: 400;
  }
  
  .survey-form label{
    font-size: 14px;
  
  }
  
  #export-button{
    background-color: #4D90CD;
    color: #fff;
    font-size: 13px;
    border-radius: 5px;
    border: none;
    padding: 6px 20px;
  }
  
  .adm-searchbox-input{
    padding: 10px 15px;
    border-radius: 5px;
    border: #ccc solid 1px;
    font-size: 14px;
    max-width: 730px;
    margin: 3px 0;
  }
  
  .adm-searchbox-select{
    padding: 10px 15px;
    border-radius: 5px;
    border: #ccc solid 1px;
    font-size: 14px;
    max-width: 730px;
    margin: 3px 0;
  }
  
  .adm-searchbox-submit{
    background-color: #4D90CD;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    border: #ccc solid 1px;
    font-size: 14px;
    max-width: 730px;
    margin: 3px 0;
  }
  
  .deactive-user-button, .deactive-user-button:hover, .deactive-user-button:focus{
    background-color: #666;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    text-decoration: none;
    border: #ccc solid 1px;
    font-size: 14px;
    max-width: 730px;
    margin: 3px 0;
  }
  
  .red-button, .red-button:hover, .red-button:active, .red-button:visited, .red-button:focus {
    background-color: #e63;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    border: #ccc solid 1px;
    text-decoration: none;
    font-size: 14px;
    max-width: 730px;
    margin: 3px 0;
  }
  
  .fancybox-content{
    border-radius: 7px
  }
  
  .adm-file-box{
    background-color: #fff;
    transition: 0.4s All;
    padding: 10px 15px 0 15px;
  }
  
  .adm-file-box:hover{
    background-color: #eee;
  }
  

  /* .gradientLine{
    background-image: linear-gradient(to right, #e32529 , #0852a0)
  } */


  .metadeView{
    display: flex;
    flex-direction: column;
    width: 40vw;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 5px !important;


  }
  @media(max-width: 1000px){
    .metadeView{
      width: 90vw;
    }
    .page-container{
      background-position: right;
    }
  }

  .inputslogins{
    width: 100%;
    outline: 0px;
    padding-left: 10px;
    border-radius: 7px;
    min-height: 50px;
    font-size: 16px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #C7C7C7;
    border-radius: 7px;
    opacity: 1;
  }
  .rowbuttonview{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: 70px;
  }

  .buttonnlogin{
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 40px;
      border-radius: 7px;
      background-color: #0753A1;
  }

  .buttonnloginText{
    color: white;
    font-size: 14px;
  }

  .textInformations{
    width: 100%;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 22.81px/34px Open Sans;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    text-align: left;
    font: normal normal normal 14px/22px Open Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
  }

  .footerviewlogincontainer{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 50%;
    height: 50px;
    padding: 20px;
  }

  .viewcontaiqrcodeweb{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-height: 120px;
  }

  .textwebsitetakeda{
    font-size: 16px;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    padding-right: 10px;
  }

  .qrcodeimg{
    width: 115px;
    height: 95px;
  }

  .capromText{
    font-size: 10px;
    color: var(--unnamed-color-ffffff);
    text-align: left;
    letter-spacing: -0.4px;
    padding-top: 20px;
    color: #FFFFFF;
    opacity: 1
  }

  .textProfileLabel{
    color: #707070;
    font-size: 14px;
    padding-top: 7px;
    text-decoration: underline;
    padding-bottom: 10px;
    cursor: pointer;
}
#textProfile{

    opacity: 0;
    position: absolute;
    z-index: -1;
}



body{
  font-family: 'Open Sans', sans-serif;
    margin: 0;
    color: #102434;
}

html {
  height: 100%;
  box-sizing: border-box;
}

a{
  text-decoration: none;
  border: none;
}

a:hover, a:active, a:visited {
  text-decoration: none;
}

h4, h3, h2{
  font-weight: 700;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.page-container{
  position: relative;
  min-height: 100vh;

}

.upper-container{
  width: 100%;
  background-color: #fff;
  /* padding: 0 25px; */

}

.nav-header{
  padding-top: 25px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 22px;
  max-width: 1800px;
  margin: 0 auto;
  min-height: 120px;
  line-height:  80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: relative;
}

.nav-btn{
  display: flex;
  flex: 1;
    vertical-align: middle;
  max-width: 70%;
      align-items: center;
}

.nav-btn:hover, .nav-btn:active, .nav-btn:visited, .nav-btn:focus{
  text-decoration: none;

}

.logo-special{
    background-image: url('https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2FSteris%2Flogos600.png?alt=media&token=5a31077c-6e4e-4c44-a3ef-bad28f1cad73');
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    max-width: 100%;
    height: 60px;
    vertical-align: middle;
}

.logo-form img {
  max-width: 100%;
  height: auto !important;
}

.logo-form {
margin: 0 -17px;
}

@media (max-width: 600px){
.logo-form {
  margin: 0 -8px;
}
}

/* versão antiga
.logo-special{
  background-image: url('assets/images/specialProgramLogo.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 200px;
  max-width: 40%;
  height: 75px;
  vertical-align: middle;
}
*/


.logo-steris{
  background-image: url('../assets/images/sterislogo.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 150px;
  height: 34px;
  vertical-align: middle;
}


/*
.logo-steris-fps{
  background-image: url('assets/images/sterisfpslogo.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 350px;
  max-width: 40%;
  height: 75px;
  vertical-align: middle;
}
*/

.profile-btn{
  align-self: flex-end; justify-content: flex-end;
}

@media(max-width: 720px){
  .nav-header{
      padding-left: 10px;
      padding-right: 10px;
  }

  /* versão antiga
   .logo-special{
    background-image: url('assets/images/specialProgramLogo.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 200px;
    max-width: 50%;
    height: 75px;
    vertical-align: middle;
  }
  .logo-steris-fps{
    max-width: 50%;
    height: 35px;
  }
*/

  .logo-steris-fps{
    max-width: 50%;
    height: 0;
  }

  .profile-btn{
    align-self: flex-start;
    position: absolute;
    top: 10px;
    right: 0;
  }


}

.get-certificado h4,
.videos-get-certificate h4 {
    font-size: 22px;
}
.get-certificado p,
.videos-get-certificate p {
    font-size: 18px;
}

.estrelas {
    display: flex;
    justify-content: center;
}  
@media(max-width: 480px){
  .logo-special{
    height: 60px;
    background-position: center;
  }
  .logo-steris-fps{
    max-width: 50%;
    height: 0;
    background-position: center;
  }
  
  /* versão antiga
   .logo-steris-fps{
    max-width: 50%;
    height: 25px;
    background-position: center;
  }
  */
}


.profile-container{
  height: 60px;
  width: 300px;
  line-height: normal;
}

.profile-thumbnail{
  float: left;
  display: block;
  width: 60px;
  height: 60px;
  background-image: url('../assets/images/profilePic.png');

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}

.profile-text{
  padding-left: 70px;
}

.profile-text h4, .profile-text p{
  margin: 5px;
}

@media (max-width: 600px){
  .profile-text{
    display: none;
    visibility: hidden;
  }
  .top_navbar .menu {
    width: 120px;
}

  .profile-container{
    width: 70px;
  }
}

.title-container{
  background-color: #ED1C24;
  height: 61px;
  width: 100%;
}

.title-container-adm{
  background-color: #4F6C87;
  height: 61px;
  width: 100%;
  margin-left: 50px;
}

.title-container p {
  display: block;
  max-width: 1800px;
  margin: 0 auto;
  color: #fff;
  font-size: 20px;
  line-height: 61px;
  padding: 0 50px;
}

.title-container-adm p{
  display: block;
  max-width: 1800px;
  margin: 0 auto;
  color: #fff;
  font-size: 24px;
  line-height: 61px;
  padding: 0 50px;

}

.main-container{
  display: block;
  margin: 0 auto 0px auto;
  padding-top: 30px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 170px;

}

@media(max-width: 600px){
  .main-container{
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
  }

}

.main-container-adm{
  display: block;
  margin: 0 auto 0px auto;
  padding-top: 30px;
  padding-left: 150px;
  padding-right: 50px;
  padding-bottom: 140px;

}

.video-button{
  color: #102434;
}

.videos-panel{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1700px;
  margin: 0 auto;

}


.video-panel::after{
  content: "";
  flex: auto;
}



.get-certificate-button{
  margin: 0;
  background-color: #ed1c24;
  color: #fff;
  padding: 7px 17px;
  margin-top: 10px;
  border-radius: 7px;
  font-weight: 500;
}

.get-certificate-button:hover{
  color: #fff;
}

.video-button{
  display: inline-block;
  position: relative;
  max-width: calc(100% * (1/4) - 30px - 1px);
  width: 400px;
  height: 450px;
  margin: 30px 0;
  background-color: #fff;
  border-radius: 7px 7px 7px 7px;
  box-shadow: #ddd 0 0 20px;
  order: 2;
}


.videos-get-certificate{
  order: 1;
  width: 1800px;
}

.grid-fix{
  max-width: calc(100% * (1/4) - 30px - 1px);
  width: 100%;
}


/*
.video-button:hover .video-playbutton{
  width: 50px;
  margin-right: -25px;
  transition-timing-function: ease-in-out;
}
*/



/* .video-check-active{
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #6d6;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2FSteris%2Fcheck-active.png?alt=media&token=292c5b1a-3877-478d-b0c4-63bce25a2ad6');
  background-size: contain;
  background-position: center center;
}
.video-check{
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2FSteris%2Fcheck-off.png?alt=media&token=9b4fe620-f58a-46ab-acf4-ff7a69b69974');
  background-size: contain;
  background-position: center center;
} */


.video-playbutton{
  position: absolute;
  top: 100px;
  right: 50%;
  margin-right: -32px;
  display: block;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2FSteris%2Fplay-button.png?alt=media&token=3022793d-71fa-4cad-826e-4d973d605f47');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  transition: All 0.1s;
}


.video-item-background{
  display: block;
  width: 100%;
  height: 250px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  /* background-image: url('assets/images/videothumbs/01.png'); */
  background-size: cover;
}

.video-text{
  margin: 30px 20px;
}
.video-text p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.video-text h3{
  font-weight: 700;
}

@media (max-width: 1680px) {
  .video-button{
    max-width: calc(100% * (1/3) - 24px - 1px);
  }

  .grid-fix{
    max-width: calc(100% * (1/3) - 24px - 1px);
  }

}

@media (max-width: 1270px) {
  .video-button{
    max-width: calc(100% * (1/2) - 20px - 1px);
  }

  .grid-fix{
    max-width: calc(100% * (1/2) - 20px - 1px);
  }
}

@media (max-width: 1000px) {
  .title-container p{
    font-size: 15px;
  }
} 
@media (max-width: 760px) {

  .videos-panel {
    justify-content: center;
  }

  .videos-panel a{
    max-width: 100%;
  }

  .grid-fix{
    max-width: 100%;
  }
}

@media(max-width: 640px){
  .title-container p{
    line-height: 30px;
  }
  .title-container{
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}


.footer{
  position: absolute;
  bottom: 0;
  width: 100%;
  /* min-height: 129px; */
  padding: 30px;
  background-color: #E6E6E6;
  color: #000;
  font-family: 'Segoe UI', sans-serif;
  font-size: 16px;
}
.footer-copyright p {
  margin: 0;
}

.footer-center{
  display: flex;
  width: 1700px;
  margin: 0 auto;
  max-width: 90%;
  font-size: 12px;
}


.footer-center div{
  display:  flex;
  flex: 1;
}

.footer-right-menu{
  text-align: right;
  align-self: flex-end;
  justify-content: flex-end;
}

.footer-right-menu a {
  color: #000;
  padding: 0 10px;
}

@media(max-width: 1000px){
  .footer{
    padding-top: 20px;
    padding-bottom: 20px;
    min-height: 110px;
  }

  .footer-center{
    flex-direction: column;
  }

  .footer-right-menu{
    text-align: center ;
    align-self: center;
    justify-content: center;
  }

  .footer-copyright{
    padding-top: 20px;
    text-align: center ;
    align-self: center;
    justify-content: center;
    order: 2;
  }
}


/* -------- Video Page --------- */
.video-flex{
  display: flex;
  width: 1600px;
  max-width: 100%;
  margin: 0 auto;
}


.video-container{
  display: inline-block;
  width: 100%;
  /* height: 700px; */
  vertical-align: top;
  margin-top: 32px;
  
}

.video-container iframe{
  width: 100%;
  max-height: 100%;
  resize: both;
  overflow: auto;
  border-radius: 7px;
}

.video-side-options{
  display: inline-block;
  width: 300px;
  /*height: 300px;*/
  vertical-align: top;
  padding-bottom: 20px;

}

.side-block{
  display: block;
  width: 100%;
  background-color: #fff;
  border-radius: 7px 7px 7px 7px;
  box-shadow: #d9d9d9 0 0 25px;
  margin: 30px;
  padding: 6px 25px; 
  /* padding: 8px 25px; */
}

@media(max-width: 1000px){
  .video-flex{
    flex-direction: column;
  }

  .video-container iframe{
    max-height: 300px;
  }
  /* .video-container{
    /* height: 300px; 
  } */

  .video-side-options{
    display: inline-block;
    width: 100%;
  }

  .side-block{
    margin: 10px 0px;
  }
}

.button-all-videos{
  display: block;
  width: 100%;
  min-height: 28px;
  line-height: 28px;
  padding-left: 40px;
  color: #000;
  font-weight: 700;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2FSteris%2Fdownload.png?alt=media&token=0022de42-084b-4ede-9bb9-220591cd214a');
  background-repeat: no-repeat;
  background-position: left;
  margin: 5px 0px;
  font-size: 18px;
}

.video-header-comment{
  display: block;
  width: 100%;
  min-height: 28px;
  line-height: 28px;
  padding-left: 50px;
  color: #000;
  font-weight: 700;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2FSteris%2Fcomments.png?alt=media&token=19cc5da1-d7a6-4ed3-923d-9a61ef89006d');
  background-repeat: no-repeat;
  background-position: left;
  margin: 5px 0px;
  font-size: 18px;
}

.button-post-comment{
  background-color: #ed1c24;
  color: #fff;
  border-radius: 7px;
  padding: 11px 40px;
}

.button-post-comment:hover, .button-post-comment:active, .button-post-comment:visited {
  color: #eee;
  text-decoration: none;
}

.side-block h3{
  font-size: 20px;
}

.file-block{
  display: block;
  margin: 10px 0;
  padding: 10px 0px 5px 50px;
  min-height: 50px;
  background-image: url('../assets/images/gIconPDF.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: left;
  transition: 0.2s All;
}

.file-block:hover{
  background-color: #f9f9f9;
}

.sidebar-menu{
  position: fixed;
  top: 0;
  left: -210px;
  width: 270px;
  height: 100%;
  background-color: #ed1c24;
  color: #fff;
  padding: 10px;
}

.sidebar-menu a{
  color: #fff;
}

.sidebar-itens a{
  display: block;
  background-image: url('../assets/images/sidebar-icons/adms.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: 215px;
  padding: 3px 5px;
  margin: 15px 0;
}

.sidebar-top{
  flex: auto;
  display: flex;
}

.sidebar-top div{
  flex: 1;

}

#sidebar-open-button{
  display: block;
  font-size: 18px;
  width: 35px;
  height: 35px;
  float: right;
}

.blue-background{
  /* background-image: url(); */
  min-height: 150px;
  margin-bottom: 0px;
}




.login-page-container{
  /* background-image: url('https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2FSteris%2Fbanner-login.png?alt=media&token=e33d22ba-21e7-4477-a8b1-c92d54af8cc5'); */
  min-height:100vh;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

}

.login-page-container-adm{
  /* background-image: url('../assets/images/bg.jpg'); */
  min-height:100vh;
  background-size: cover;
  background-repeat: no-repeat;

}

.blue-background h2{
  color: #000;
  font-weight: 400;
  font-size: 26px;
}

/* .login-form{

} */
/*Formik*/
input.form-control, select.form-control {
  padding: 15px 15px;
  border-radius: 5px;
  border: 1px solid #C7C7C7;
  font-size: 14px;
  max-width: 730px;
  width: 100%;
  height: auto;
  margin: 3px 0;
}

.form-control#inputupload  {
max-width: 475px;
}
.login-button{
  padding: 17px 15px;
  border-radius: 5px;
  border: 1px solid #C7C7C7;
  font-size: 14px;
  max-width: 260px;
  width: 90%;
  margin: 3px 0;
  background-color: #FFAA00;
  color: #002D6E;
  font-weight: 600;
  font-size: 16px;
  text-align:center;
}

.is-invalid~.invalid-feedback, .is-invalid~.invalid-tooltip, .was-validated :invalid~.invalid-feedback, .was-validated :invalid~.invalid-tooltip {
  display: block;
}
button.btn.btn-line {
  padding: 17px 15px;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  max-width: 260px;
  width: 90%;
  margin: 3px 0;
  background-color: #FFAA00;
  color: #002D6E;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}
a.inscrito {
cursor: pointer;
color: #fff;
padding: 17px 0px;
margin: 0 15px;
}
.is-invalid~.invalid-feedback {
display: block;
}
.invalid-feedback {
display: none;
width: 100%;
margin-top: .1rem;
font-size: 15px;
color: #dc3545;
}
/* Fim Formik*/


.login-form input[type=text], .login-form input[type=password]{
  padding: 15px 15px;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  max-width: 500px;
  width: 100%;
  margin: 3px 0;
  border: 1px solid #C7C7C7;
}

.login-form select{
  padding: 15px 15px;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  max-width: 730px;
  width: 100%;
  margin: 3px 0;
}



.login-button{
  padding: 17px 15px;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  max-width: 260px;
  width: 90%;
  margin: 3px 0;   
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  text-align:center;
  background-color: #AE011A;
}
.button-login{
background-color: #ED1C24;
}

.button-register:hover{
background-color: #AE011A;
color: #fff;;
}


.login-button-adm {
  padding: 17px 15px;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  max-width: 260px;
  width: 90%;
  margin: 3px 0;
  background-color: #175E9E;
  color: #FFF;
  font-weight: 600;
  font-size: 16px;
}

.language-btn{
  width: 40px;
  margin: 0 5px;
  background-image: url('../assets/images/language_flags/BRAZIL.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border-radius: 7px;
}

.flag-pt-br{
  background-image: url('../assets/images/language_flags/BRAZIL.png');
}

.flag-en{
  background-image: url('../assets/images/language_flags/UK.png');
}

.flag-es{
  background-image: url('../assets/images/language_flags/SP.png');
}

@media(max-width: 950px){
    .lang-area span{
      visibility: hidden;
      display: none;
    }
}

@media(max-width: 700px){
    
  .lang-area{
    position: relative;
    top: 4px;
    right: 35px;
    line-height: 11;
  }
  
  /* versão antiga
  .lang-area{
    position: absolute;
    top: 0px;
    right: 0px;
    line-height: 30px;
  }*/


  .language-btn{
      width: 30px;
    margin: 0 2px;
  }

  .login-button{
    margin: 5px 0;
  }
}



.form-final-line{
  display: block;
  /* padding-bottom: 20px; */
  max-width: 500px;
}

.form-final-line div{
  display: flex;
  width: 100%;
}

@media(max-width: 700px){
  .form-final-line{
    flex-direction: column;
    display: flex;
  }

  .login-button{
    max-width: 100%;
    width: 100%;
  }
}

.form-password-reset {
  color: #23527c;
  justify-content: flex-end;
}

.form-password-reset:hover{
  color: #000;

}

.button-login-active:hover{
  background-color:  #23527c;
  color: #fff;
}

.resend-email-button{
  background-color:  #FFAA00;
  color: #002D6E;
  padding: 3px 15px;
  border-radius: 7px;
  font-weight: 600;
}

.profile-menu-relative-container{
  display:none;
  background-color: #cfc;
  max-width: 1800px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

@media(max-width: 720px){
  .profile-menu-relative-container{
    max-width: 1px;
    position: relative;
  }

}
.conatinerPicture {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.profile-menu-shadow {
    width: 300px;
    z-index: 1;
    mix-blend-mode: multiply;
    transition: all 250ms;
    box-shadow: 0 0 5px rgba(100, 106, 132, 0.5), 0 5px 12px rgba(100,106,136,.8);
  border-radius: 5px;
  padding: 10px 10px;
}

.profile-menu-shadow a{
  margin: 0;
  display: flex;
  padding: 5px 0px 5px 30px;
  align-items: center;
}
.icon-Edit {
  margin: 0 5px;
  font-size: 20px;
}

.edit-main-container{
  display: block;
  max-width: 850px;
  width: 90%;
  margin: 0 auto;
  padding: 0px;

}

.edit-main-container h3{
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center left;
  padding:3px 0 3px 5px;
  margin: 25px 0;
  font-size: 18px;
}

.edit-profile-title{
  background-image: url('../assets/images/formicons/user.png');
}

.edit-main-container p{
  margin: 15px 0;
}

.edit-general-info .form-final-line button {
  background-color: #ed1c24;
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  transition: 0.2s all;
  -webkit-transition: 0.2s all;
  -moz-transition: 0.2s all;
  -ie-transition: 0.2s all;
  -o-transition: 0.2s all;
}
img.image-upload {
display: block;
width: 150px;
height: 150px;
border-radius: 50%;
background-size: contain;
background-position: center;
}
button.buttonImageUpload,
.form-group img {
object-fit: cover;
width: 200px;
height: 200px;
border-radius: 50%;
cursor: pointer;
}


.edit-main-container input[type=text], .edit-main-container input[type=password], .edit-main-container input[type=number]{
  padding: 10px 40px 10px 20px;
  border: solid 1px #DDD;
  width: 475px;
  max-width: 100%;
  border-radius: 5px;
  font-size: 16px;
  /* background-image: url('assets/images/formicons/Pen.png'); */
  background-repeat: no-repeat;
  background-position: center right 10px;
  background-size: 24px 24px;
}

.edit-general-info label{
  min-width: 75px;
  font-size: 16px;
  font-weight: 700;
}

.edit-new-password input[type=text], .edit-new-password input[type=password]{
  padding: 10px 40px 10px 20px;
  border: solid 1px #DDD;
  width: 360px;
  max-width: 100%;
  border-radius: 5px;
  font-size: 16px;
}


.edit-general-info textarea{
  resize: none;
  height: 120px;
  padding: 10px 40px 10px 20px;
  border: solid 1px #DDD;
  width: 440px;
  max-width: 100%;
  border-radius: 5px;
  font-size: 16px;
}

.edit-new-password label{
  min-width: 125px;
  font-size: 16px;
  font-weight: 700;
}

.big-profile-picture{
  display: block;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-size: contain;
  background-position: center;
}

.star-container{
  font-size: 35px;
  transition: 0.3s All;
}

.fa{
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 30px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: 0.3s All;

}


.star-rating .fa-star{color:#4B90CD;transition: 0.3s All;}

/* Customize the label (the container) */
.container {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #4B90CD;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: inline-block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.container{
  min-width: 75px;
  font-size: 14px;
  font-weight: 400;
}

.survey-form label{
  font-size: 14px;

}

#export-button{
  background-color: #ed1c24;
  color: #fff;
  font-size: 13px;
  border-radius: 5px;
  border: none;
  padding: 6px 20px;
}

.adm-searchbox-input{
  padding: 10px 15px;
  border-radius: 5px;
  border: #ccc solid 1px;
  font-size: 14px;
  max-width: 730px;
  margin: 3px 0;
}

.adm-searchbox-select{
  padding: 10px 15px;
  border-radius: 5px;
  border: #ccc solid 1px;
  font-size: 14px;
  max-width: 730px;
  margin: 3px 0;
}

.adm-searchbox-submit{
  background-color: #ed1c24;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  border: #ccc solid 1px;
  font-size: 14px;
  max-width: 730px;
  margin: 3px 0;
}

.deactive-user-button, .deactive-user-button:hover, .deactive-user-button:focus{
  background-color: #666;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;
  border: #ccc solid 1px;
  font-size: 14px;
  max-width: 730px;
  margin: 3px 0;
}

.red-button, .red-button:hover, .red-button:active, .red-button:visited, .red-button:focus {
  background-color: #e63;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  border: #ccc solid 1px;
  text-decoration: none;
  font-size: 14px;
  max-width: 730px;
  margin: 3px 0;
}

.fancybox-content{
  border-radius: 7px
}

.adm-file-box{
  background-color: #fff;
  transition: 0.4s All;
  padding: 10px 15px 0 15px;
}

.adm-file-box:hover{
  background-color: #eee;
}


.login-page-container{
  /* background-image: url('https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/companies%2FSteris%2Fbanner-login.png?alt=media&token=e33d22ba-21e7-4477-a8b1-c92d54af8cc5'); */
  /* min-height:100vh; */
  min-width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: top;
  overflow: hidden;

}

.centerbackground{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  background-position-x: 0px;
  overflow: hidden;
}

@media(max-width: 900px){
  .centerbackground{
    background-position: right top;
  }
}

.audioContainer{
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70px;
  background-color: white;
  height: 25px;
  border: solid;
  border-width: 1px;
  border-radius: 2px;
  border-color: #707070;
}

.audioContainerCo{
  display: flex;
  width: 70px;
  height: 75px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 
}


.audioContainerC{
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90px;
  color:black;
}



.imgggggg{
  display: inline;
  /* max-width: 100%; */
  
  object-position: center;
  object-fit: unset;
  margin: auto;
  }


