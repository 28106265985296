
.post-module {
    position: relative;
    z-index: 1;
    display: block;
    background: #FFFFFF;
    min-width: 270px;
    height: 430px;
  }

  .thumbnail {
    margin-bottom: 0;
}
  .post-module .thumbnail .date .day {
    font-size: 18px;
  }
  .post-module .thumbnail .date .month {
    font-size: 12px;
    text-transform: uppercase;
  }
  .post-module .thumbnail img {
    display: block;
    width: 120%;
  }
  .post-module {
  
    min-height: 500px;
  }
  .post-module .post-content {
    position: absolute;
    background: #FFFFFF;
    width: 100%;
    padding: 30px;
    -webkti-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
    -moz-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
    -ms-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
    -o-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
    transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  }
  .post-module .post-content .category {
    position: absolute;
    top: -34px;
    left: 0;
    background: #e74c3c;
    padding: 10px 15px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .post-module .post-content .title {
    margin: 0;
    padding: 0 0 10px;
    color: #333333;
    font-size: 20px;
    font-weight: 700;
  }
  .post-module .post-content .description {
    color: #666666;
    font-size: 14px;
    line-height: 1.8em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .container-card {
    margin: 10px auto;
    display:flex;
    flex-wrap:wrap;
  }
  .container-card:before,
  .container-card:after {
    content: '';
    display: block;
    clear: both;
  }
  .container-card .column {
    width: 400px;
    margin:0 auto;
    -webkti-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #0000000f;
  }

  .container-card .column:hover {
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
  .container-card .column .demo-title {
    margin: 0 0 15px;
    color: #666666;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .container-card .info {
    width: 500px;
    margin: 50px auto;
    text-align: center;
  }
  .container-card .info h1 {
    margin: 0 0 15px;
    padding: 0;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
  }
  .container-card .info span {
    color: #666666;
    font-size: 12px;
  }
  .container-card .info span a {
    color: #000000;
    text-decoration: none;
  }
  .container-card .info span .fa {
    color: #e74c3c;
  }
  

  .virewew{
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    background-color:#faebd6;
    min-height: 65px;
    padding: 10px;
  }

  .virewews{
    width: 100%;
    color:#000000;
    font-size: 14px;
    text-align: center;
  }