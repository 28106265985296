.profile-menu-relative-container.active {
    display: block;
    visibility: visible;
    transform: translateY(0);
  }

.top_navbar{
	position: relative;
	top: 0px;
	left: 0px;
	right: 0px;
	padding-left: 20px;
	padding-right: 20px;
	flex-direction: row;
	width: 100%;
	height: 60px;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.wrapper.hover_collapse {
  max-width: 1800px;
  margin: 0 auto;
  padding: 10px 0;
}
img.logo {
  max-width: 200px;
  width: 100%;
}

.top_navbar .logo{
	width: 200px;
	font-size: 25px;
	font-weight: 600;
	/* padding: 0 25px; */
	color: #007dc3;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.leftAlginsLogo{
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	left: 0px;
}
.top_navbar .logo span{
	font-weight: 400;
}

.top_navbar .menu{
	width:auto;
	/* padding: 0; */
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.top_navbar .hamburger{
	font-size: 25px;
	cursor: pointer;
	color: #005faf;
}

.top_navbar .hamburger:hover{
	color: #007dc3;
}


.top_navbar .menu .profile img{
	width: 25px;
	height: 25px;
}

.top_navbar .menu .profile{
	display: flex;
	align-items: center;
	cursor: pointer;
}

.top_navbar .menu .profile .name{
	margin: 0 15px;
}

.hover_collapse .sidebar{
	width: 70px;
}

.hover_collapse .sidebar ul li a .text{
	display: none;
}

.sidebar{
	position: fixed;
	top: 60px;
	left: 0;
	width: 250px;
	height: 100%;
	background: #005faf;
}

.sidebar ul li a{
	display: block;
	padding: 12px 25px;
	border-bottom: 1px solid #0e94d4;
	color: #0e94d4;
	transition: all 0.2s ease;
}

.sidebar ul li a .icon{
	font-size: 18px;
	vertical-align: middle;
	transition: background 0.2s ease;
}

.sidebar ul li a .text{
	margin-left: 10px;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.sidebar ul li a:hover{
	background: #0e94d4;
	color: #fff;
}

.click_collapse .sidebar{
	transition: all 0.2s ease;
}

.main_container{
	margin-top: 60px;
	margin-left: 70px;
	padding: 25px;
	width: calc(100% - 70px);
}

.main_container .content{
	background: #fff;
	padding: 25px;
	margin-bottom: 25px;
	text-align: justify;
}

.click_collapse .main_container{
	width: calc(100% - 250px);
	margin-left: 250px;
	transition: all 0.2s ease;
}

@media (max-width: 640px){
  #profile-menu{
    right: -30px;
    top: 100px;
  }
  .form-password-reset {
	color: #fff!important;
	font-weight: 600;
}
  
}

@media (max-width: 1366px){
	/* .top_navbar .menu{
	 width: 330px;
	} */
	.form-password-reset {
		color: #fff!important;
	}
	
  }

#profile-menu{
  display: inline-block;
  position: absolute;
  right: -93px;
  top: 40px;
  background-color: #fff;
  margin-right: 100px;
  width: 300px;
  border-radius: 5px;
}



.dropdown {
	position: relative;
	display: inline-block;
  }
  
  .dropdown-content {
	display: none;
	flex-direction: column;
	align-items: flex-start;
	width: 220px;
	position: absolute;
	background-color: #f7f7f7 !important;
	min-width: 160px;
	height: 80px;
	justify-content: space-around;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	padding: 12px 16px;
	z-index: 1;
  }
  
  .dropdown:hover .dropdown-content {
	display: flex;
  }